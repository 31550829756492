<template>
  <div class="lxp-loading">
    <SystemAlert v-if="isSystemAlertEnabled" />
    <OnboardingLayout>
      <template v-slot:content-section>
        <LxpLoader />
      </template>
    </OnboardingLayout>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpLoader } from "didactica";

import userStatus from "../core/mixins/userStatus";

import OnboardingLayout from "@/components/Layout/OnboardingLayout.vue";
import SystemAlert from "./SystemAlert.vue";
export default {
  mixins: [userStatus],
  components: { LxpLoader, OnboardingLayout, SystemAlert },
  computed: {
    ...mapGetters(["isSystemAlertEnabled"])
  },
  created() {
    this.checkUserStatus();
  }
};
</script>
<style lang="scss">
.lxp-loading {
  .lxp-loader {
    min-height: 400px;
  }
}
</style>
